import { LoginUser } from '../login.types'
import { guest } from '@/plugins/axios'

export const getBranch = async (credentials: {
  email: string
  password: string
}): Promise<LoginUser> => {
  const { data } = await guest.post('/onboard/branch', { credentials })
  return data
}

export const confirmBranch = async (credentials: {
  orgId: number
  token: string
}): Promise<{ jwt: string }> => {
  const { data } = await guest.post('/onboard/confirm', credentials)
  return data
}

export const checkFirstLogin = async (id: number): Promise<boolean> => {
  try {
    const path = '/auth/changepassword/request'
    const { data } = await guest.get(path, { params: { id } })
    return data
  } catch (err) {
    throw err
  }
}
