<template>
  <div>
    <p class="title is-4 is-valigned is-spaced auth-text">
      <span class="icon is-medium">
        <i class="fal fa-code-branch" />
      </span>
      <span>Choose a branch</span>
    </p>
    <div class="content" v-if="availableBranches.length">
      <p class="subtitle is-6 auth-text">
        Please choose your default branch for this session. You will be able to
        change this once you're logged in.
      </p>
      <div class="columns is-multiline">
        <div
          class="column is-12"
          v-for="(branch, index) in availableBranches"
          :key="index"
        >
          <button
            @click="setOrgId(branch.id)"
            class="button is-fullwidth is-medium is-primary auth-text"
            :class="{ 'is-outlined': orgId !== branch.id }"
            :disabled="!branch.active"
          >
            <transition enter-active-class="animated tada" mode="out-in">
              <span class="icon has-text-soft" v-if="orgId === branch.id">
                <i class="fal fa-check-circle" />
              </span>
            </transition>
            <span>{{ branch.name }}</span>
          </button>
        </div>
        <div class="column is-12 has-text-right" v-if="!allDisabledBranches">
          <button
            class="button is-size-6-mobile is-info"
            :disabled="$wait.is('loading') || !orgId"
            :class="{ 'is-loading': $wait.is('loading') }"
            @click="confirm"
            data-test-id="confirm-branch-button"
          >
            <span>Confirm</span>
            <span class="icon">
              <i class="fas fa-chevron-circle-right" />
            </span>
          </button>
        </div>
        <div class="column is-12 auth-text" v-if="disabledBranches">
          One or more of your branches have been disabled, please contact
          support for more assistance. You may still log in with your active
          branch(es).
        </div>
      </div>
    </div>
    <div class="content" v-if="unavailableBranches.length">
      <p class="auth-text" v-if="$reseller !== 'experian'">
        You are attached to {{ unavailableBranches.length }}
        {{ unavailableBranches.length === 1 ? 'branch' : 'branches' }} on
        Experian. To access AutoCheck products please use the
        <a
          class="has-text-weight-bold auth-text"
          href="https://experian.cazana.com"
          >Experian&nbsp;Portal <i class="fal fa-external-link-alt"
        /></a>
      </p>
      <p class="auth-text" v-else-if="$reseller === 'experian'">
        You are attached to {{ unavailableBranches.length }}
        {{ unavailableBranches.length === 1 ? 'branch' : 'branches' }} on
        Cazana. To access Cazana products please use the
        <a
          class="has-text-weight-bold auth-text"
          href="https://trade.percayso-vehicle-intelligence.co.uk"
          >Trade&nbsp;Portal <i class="fal fa-external-link-alt"
        /></a>
      </p>
    </div>
  </div>
</template>

<script>
import { confirmBranch } from 'modules/login/services/login.service'
export default {
  name: 'LoginSelectBranch',
  data: () => ({ orgId: null }),
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    disabledBranches() {
      return this.user.branches.some(branch => !branch.active)
    },
    allDisabledBranches() {
      return this.availableBranches.every(branch => !branch.active)
    },
    availableBranches() {
      return this.user.branches.filter(branch => branch.type !== 'experian')
    },
    unavailableBranches() {
      return this.user.branches.filter(branch => branch.type === 'experian')
    }
  },
  methods: {
    setOrgId(id) {
      this.orgId = id !== this.orgId ? id : null
    },
    async confirm() {
      this.$wait.start('loading')
      try {
        const { jwt } = await confirmBranch({
          token: this.user.token,
          orgId: this.orgId
        })
        return this.$emit('complete', { jwt })
      } catch (err) {
        const fallback = 'Something went wrong. We have been notified.'
        this.error = err?.response?.data?.error?.message ?? fallback
        this.$notify(this.error)
      } finally {
        this.$wait.end('loading')
      }
    }
  },
  mounted() {
    if (this.availableBranches.length) {
      this.orgId = this.availableBranches[0].id
    }
  }
}
</script>
